<template>
  <div class="grid-container pad-top--xl">
    <div class="grid-x grid-padding-x">
      <div class="cell small-12 medium-4 medium-offset-4">
        <h4 class="pad-top">Request Password</h4>
        <form>
          <label>Email</label>
          <input
            name="email"
            type="text"
            v-model="email"
          >
        </form>
        <button
          class="button"
          @click="sendLink">
          Submit
        </button>
      </div>
    </div>
    <app-message
      v-if="showMessage === true"
      :messageText="message"
      :link="false"
      :linkText="false"
      @closeMessage="showMessage = false" />
  </div>
</template>

<script>
import Message from '@/components/Messages/Message.vue';
import axios from '../../axios';

export default {
  name: 'RequestPasswordReset',
  components: {
    appMessage: Message,
  },
  data() {
    return {
      email: '',
      showMessage: false,
      message: '',
    };
  },
  methods: {
    sendLink() {
      const postData = {};
      postData.email = this.email;
      axios.post('/users/requestPasswordReset.json', postData)
        .then((response) => {
          this.showMessage = true;
          this.message = response.data.message;
        });
    },
  },
};
</script>
